import {
    PageContainerDPC,
    PrintButtonDPC,
    GalleryFlickrDPC
} from '@dpc-fe/shared';
import {graphql} from 'gatsby';
import React from 'react';
import 'moment/locale/it';
import moment from "moment";
import 'moment/locale/it';
import {prop} from "../functions/functions";
import "moment/locale/it";

export default ({data, pageContext}) => {
    moment.locale('it');

    const breadcrumbItem = {
        name: data.node.field_titolo_esteso,
        slug: data.node.fields && data.node.fields.slug ? data.node.fields.slug : null,
        active: true
    }

    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                          menuItems={pageContext.menu}
                          slugTraduzione={data.node.relationships.field_riferimento_traduzione ? data.node.relationships.field_riferimento_traduzione.fields.slug : ''}
                          pageId={data.node.drupal_internal__nid}
                          title={data.node.title}
                          env={process.env.ENV}
                          lang={pageContext.lang}
                          //checkTorna={true}
                          //linkTorna={`/${pageContext.lang}${data.elenco?.fields?.slug}`}
                          //textTorna={data.elenco?.title}
                          elencoId={data.elenco?.drupal_internal__nid}
                          breadcrumbItem={breadcrumbItem}
                          immagineShare={data.node.relationships?.field_immagine_dettaglio ? process.env.PORTALE_URL + data.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL: process.env.PORTALE_URL + pageContext.socialImg?.relationships?.image?.localFile?.publicURL}
                          abstractShare={data.node.body?.processed ? data.node.body.processed.replace(/<\/?[^>]+(>|$)/g, "") : ''}
                          slug={process.env.PORTALE_URL + '/' + pageContext.lang + data.node.fields.slug}
                          minisiti={prop}
                          menuFooterItems={pageContext.menuFooter}
        >
            <section className="container article-card-simple sezione-dettaglio">
                <h1>Foto e video</h1>
                <div className="px-custom">
                    <article className="card-wrapper pb-0">
                        <div className="card">
                            <div className="card-body">
                                <div className="category-top">
                                    <span className="data">{moment(data.node.field_data).format('D MMMM Y')}</span>
                                </div>
                                <h2 className="h5 card-title">{data.node.field_titolo_esteso}</h2>
                                <div className="card-text"
                                     dangerouslySetInnerHTML={{__html: data.node.body ? data.node.body.processed : ''}}/>
                            </div>
                        </div>
                    </article>
                    {<div className="gallery-photo-video-embed">
                        <GalleryFlickrDPC
                            link={data.node.field_link_flickr}/>
                    </div>}
                    <PrintButtonDPC/>
                </div>
            </section>
        </PageContainerDPC>
    );
};


export const query = graphql`
query($slug: String!) {
    node: nodeGalleriaFlickr (fields: { slug: { eq: $slug } }){
        drupal_internal__nid
        title
        field_titolo_esteso
        field_data
        field_categoria_primaria
        body {
            processed
        }
        fields {
            slug
        }
        field_link_flickr
        relationships{
        field_sottodominio {
          name
        }
        field_riferimento_traduzione {
            fields {
            slug
            }
        }
        field_immagine_dettaglio {
            field_alt
            field_didascalia
            relationships {
              image:field_immagine {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
    },
    elenco:nodeElencoFotoEVideo (field_codice_lingua: {eq: false}, relationships: {field_sottodominio: {name: {eq: "Portale"}}}) {
      title
      field_titolo_esteso
      drupal_internal__nid
      fields {
        slug
      }
    }
}
`;
